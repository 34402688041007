var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"content-header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row mb-3"},[_vm._m(0),_c('div',{staticClass:"col-sm-6",attrs:{"hidden":this.userRole == 'ROLE_MERCHANT'}},[_c('ol',{staticClass:"breadcrumb float-sm-right"})])]),_vm._m(1)])]),(_vm.vendingList?.length > 0)?_c('div',[_c('div',{staticClass:"row pl-3 pr-3"},_vm._l((_vm.vendingList),function(items){return _c('div',{key:items.id,staticClass:"col-lg-3 col-md-3 col-sm-6"},[_c('div',{staticClass:"card vm-card-vending"},[_c('div',{staticClass:"card-header text-neutral"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col d-flex align-items-center"},[_vm._v(" "+_vm._s(items.vending_id)+" ")]),_c('div',{staticClass:"col d-flex justify-content-end"},[_c('div',{staticClass:"dropdown"},[_vm._m(2,true),_c('div',{staticClass:"dropdown-menu",attrs:{"aria-labelledby":"dropdownMenuButton"}},[_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{
                        name: 'AssignVendingDinamis',
                        query: { 
                          vendingId: items.id
                        },
                        params: {
                          id: items.id,
                          row: items.row_slot ? items.row_slot : 5,
                          col: items.col_slot ? items.col_slot : 8,
                        },
                      }}},[_vm._v(" Atur Stok "),_c('i',{staticClass:"fas fa-chevron-right",staticStyle:{"margin-left":"50px"}})]),_c('span',{staticClass:"dropdown-item",staticStyle:{"cursor":"pointer"},attrs:{"disabled":""}},[_vm._v(" Ganti Status: ")]),_c('div',{on:{"click":function($event){return _vm.save(items.id, _vm.value)}}},[_c('span',{staticClass:"dropdown-item text-primary",on:{"click":function($event){return _vm.save(items.id, 'Online')}}},[_c('span',{staticClass:"fas fa-circle mr-2",staticStyle:{"font-size":"8px"},attrs:{"data-status":"online"}}),_vm._v("Online ")]),_c('span',{staticClass:"dropdown-item text-warning",on:{"click":function($event){return _vm.save(items.id, 'Perbaikan')}}},[_c('span',{staticClass:"fas fa-circle mr-2",staticStyle:{"font-size":"8px"},attrs:{"data-status":"maintenance"}}),_vm._v("Perbaikan ")]),_c('span',{staticClass:"dropdown-item text-danger",on:{"click":function($event){return _vm.save(items.id, 'Offline')}}},[_c('span',{staticClass:"fas fa-circle mr-2",staticStyle:{"font-size":"8px"},attrs:{"data-status":"offline"}}),_vm._v("Offline ")])])],1)])])])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"vending-title"},[_vm._v(_vm._s(items.name))]),_c('div',{staticClass:"text-primary company-title"},[_c('span',{staticClass:"fas fa-building mr-2"}),_vm._v(_vm._s(items.name)+" ")])]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-6"},[_c('span',{class:items.status === 'Online' ? 'badge' : items.status === 'Offline' ? 'badge-offline' : 'badge-maintenance'},[_vm._v(_vm._s(items.status))])]),_c('div',{staticClass:"col-sm-12 col-md-6"})])])])])}),0)]):_c('div',{staticClass:"image-empty"},[_c('img',{attrs:{"src":require("../../assets/img/emptyMonitorVM.png")}})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6",staticStyle:{"padding-left":"26px !important"}},[_c('h1',{staticClass:"m-0 text-dark",staticStyle:{"font-size":"2em"}},[_vm._v(" Pengawasan Vending Machine ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-9"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn btn-sm",attrs:{"type":"button","id":"dropdownMenuButton","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('span',{staticClass:"fas fa-ellipsis-h text-neutral"})])
}]

export { render, staticRenderFns }